import React from 'react'
import Hero from '../Components/Hero';

export default function Home() {
  return (
    <div id="content">
      <home>
        <h1>Welcome to the Homepage</h1>
      </home>
      <Hero />
    </div>
  )
}
